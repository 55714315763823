import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import distributionIcon from '@material-ui/icons/BlurOn';
import emojiNatureIcon from '@material-ui/icons/EmojiNature';
import pollIcon from '@material-ui/icons/BallotOutlined';
import starsIcon from '@material-ui/icons/Stars';
import bannerIcon from '@material-ui/icons/WbIridescentOutlined';
import Link from '@material-ui/core/Link';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Sidebar from './Sidebar';
import Footer from './Footer';
import DimensionalCard from './DimensionalCard';
import OurCard from './OurCard';
import Particle from './react-three-fiber';
import TransactionVisualisation from './TransactionVisualisation';
import Toolbar from '@material-ui/core/Toolbar';
const featuredImage = require('./img/featured_3.jpg').default;
const aragonImage = require('./img/aragon_1.jpg').default;
const uniswapLogo = require('./img/uniswap_logo_white.svg').default;
const telegramLogo = require('./img/telegram_logo.svg').default;
const discordLogo = require('./img/discord_logo.svg').default;
const redditLogo = require('./img/reddit_logo.svg').default;

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  mainContainer: {
    // overflowY: 'scroll',
    position: 'relative',
    marginBottom: '50px'
  },
  featuredPostContainer: {
    marginBottom: theme.spacing(4),
  },
  featuredContainerDualMargin: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4)
  },
  transactionVisualisationContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  toolbarSecondary: {
    // marginTop: theme.spacing(4),
    justifyContent: 'center',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
  },
}));

const sections = [
  { title: 'Telegram', url: 'https://t.me/DonutTraders', icon: telegramLogo },
  { title: 'Discord', url: 'https://discord.gg/TxNZAT26VA', icon: discordLogo },
  { title: 'Reddit', url: 'https://www.reddit.com/r/ethtrader', icon: redditLogo },
];

const mainFeaturedPost = {
  title: 'What are DONUTs?',
  description: (<span style={{textShadow: 'rgb(0, 0, 0) 0px 0px 4px'}}>Tokenized under the ERC-20 standard, DONUTs are the pioneer of Reddit's <Link style={{color: '#f31996'}} color="inherit" href="https://www.reddit.com/community-points">Community Points</Link> initiative, the official token of the <Link style={{color: '#f31996'}} href="https://www.reddit.com/r/ethtrader" color="inherit">/r/ethtrader</Link> subreddit & the first mainnet Ethereum integration with Reddit.</span>),
  image: featuredImage,
  imageText: 'main image description',
};

const aragonFeature = {
  title: 'DAONUT',
  description: (<span style={{textShadow: 'rgb(0, 0, 0) 0px 0px 4px'}}>The DONUT-driven <Link style={{color: '#f31996'}} color="inherit" href="https://client.aragon.org/#/ethtraderdao.eth">DAO</Link> built on <Link style={{color: '#f31996'}} href="https://aragon.org/" color="inherit">Aragon</Link>.</span>),
  image: aragonImage,
  imageText: 'main image description',
};

const featuredPosts = [
  {
    title: 'Polling',
    description: 'DONUTs can be used to natively vote on polls on /r/ethtrader.',
    icon: pollIcon,
    iconType: 'icon',
  },
  {
    title: 'Special Memberships',
    description: (<span>Use DONUTs to subscribe to <Link href="https://www.reddit.com/web/special-membership/ethtrader" style={{color: '#f31996'}} color="inherit">special memberships</Link> on /r/ethtrader.</span>),
    icon: starsIcon,
    iconType: 'icon',
  },
  {
    title: 'Banner Advertising',
    description: (<span>Using a <Link href="https://medium.com/@simondlr/what-is-harberger-tax-where-does-the-blockchain-fit-in-1329046922c6" style={{color: '#f31996'}} color="inherit">Harberger Tax</Link>, the banner image at the top of /r/ethtrader is for sale to anyone using DONUTs.</span>),
    icon: bannerIcon,
    iconType: 'icon',
  },
  {
    title: 'Fairly Distributed',
    description: 'Prior to Uniswap, DONUTs were distributed according to Reddit Karma earned from posts on /r/ethtrader.',
    icon: distributionIcon,
    iconType: 'icon',
  },
  {
    title: 'Community Driven',
    description:
      'DONUTs are the result of sustained grassroots growth.',
    icon: emojiNatureIcon,
    iconType: 'icon'
  },
  {
    title: 'Listed on Uniswap',
    description:
      'Nobody can pause trades or force sells.',
    icon: uniswapLogo,
    iconType: 'svg',
    link: 'https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xc0f9bd5fa5698b6505f643900ffa515ea5df54a9'
  },
];

export default function Blog() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Particle/>
      <CssBaseline />
      <Container className={classes.mainContainer} maxWidth="lg">
        <Header title="DONUT" sections={sections} />
        <main>
          <div className={classes.featuredPostContainer}>
            <MainFeaturedPost post={mainFeaturedPost} />  
          </div>
          <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <Grid item style={{width: '100%'}} sm={12} md={4} lg={4}>
                  <OurCard 
                    title={post.title}
                    description={post.description}
                    icon={
                      post.iconType === 'icon' ? <post.icon style={{ fontSize: 80 }}/> : <img src={post.icon} height={80}/>
                    }
                    link={post.link}
                  />
              </Grid>
            ))}
          </Grid>
          <div className={classes.featuredContainerDualMargin}>
            <MainFeaturedPost post={aragonFeature} />  
          </div>
          {/* <div className={classes.transactionVisualisationContainer}>
            <TransactionVisualisation height={200} />
          </div> */}
        </main>
        
      </Container>
      <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
          {sections.map((section) => (
            <Link
              color="inherit"
              noWrap
              key={section.title}
              variant="body2"
              href={section.url}
              className={classes.toolbarLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img width={35} src={section.icon}></img>
            </Link>
          ))}
        </Toolbar>
        <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
          <p style={{textAlign: 'center'}}>This website is an unofficial DONUT community initiative made for informational purposes. DONUTs are not an investment instrument.</p>
        </Toolbar>
    </React.Fragment>
  );
}
