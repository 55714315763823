import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import DimensionalCard from './DimensionalCard';

const useStyles = makeStyles({
  root: {
      textAlign: 'center',
      width: '100%',
      height: '100%',
  },
  media: {
    height: 140,
  },
  iconContainer: {
    paddingTop: 15,
  },
});

interface IProps {
    title: string
    description: any
    icon: any
    link?: string
}

function LinkWrap({ children, link } : { children: any, link?: string }) {
  if(!link) {
    return children;
  }
  return (
    <Link
      color="inherit"
      noWrap
      variant="body2"
      href={link}
      rel="noopener noreferrer"
      target="_blank"
      style={{textDecoration: 'none'}}
    >
      {children}
    </Link>
  )
}

export default function MediaCard(props: IProps) {
  const classes = useStyles();
  const {title, description, icon, link} = props;

  return (
    <DimensionalCard>
      <Card className={classes.root}>
        <LinkWrap link={link}>
          <CardActionArea className={classes.root} style={!link ? { cursor: 'default' } : {}}>
            <div className={classes.iconContainer}>
                {icon}
            </div>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {title}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {description}
              </Typography>
            </CardContent>
          </CardActionArea>
        </LinkWrap>
      </Card>
    </DimensionalCard>
  );
}