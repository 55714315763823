import React, { useRef, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { makeStyles } from '@material-ui/core/styles';
import { useSpring, animated } from 'react-spring'

const useStyles = makeStyles((theme) => ({
    card: {
        background: 'grey',
        borderRadius: '5px',
        backgroundImage: 'url(https://drscdn.500px.org/photo/435236/q%3D80_m%3D1500/v2?webp=true&sig=67031bdff6f582f3e027311e2074be452203ab637c0bd21d89128844becf8e40)',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        boxShadow: '0px 10px 30px -5px rgba(0, 0, 0, 0.3)',
        transition: 'box-shadow 0.5s',
        willChange: 'transform',
      }
  }));

interface IProps {
  mode?: string
  children: any
}

const DimensionalCard = (props : IProps) => {
    const { mode, children } = props;
  const [properties, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }))
  const classes = useStyles();
  const [divCenterY, setDivCenterY] = useState();
  const [divCenterX, setDivCenterX] = useState();
  const dimensionalCardRef = useRef<HTMLDivElement>(null);
  const getIntensityX = (mode?: string) => {
    switch(mode) {
      case 'light':
        return 250
      default:
        return 100
    }
  }
  const getIntensityY = (mode?: string) => {
    if(dimensionalCardRef.current){
      let boundingRect = dimensionalCardRef.current.getBoundingClientRect();
      return getIntensityX(mode) / (boundingRect.width / boundingRect.height) / (boundingRect.width / boundingRect.height);
    }else{
      return 100
    }
  }
  const calc = (x: number, y: number) => {
    if(dimensionalCardRef.current){
      let boundingRect = dimensionalCardRef.current.getBoundingClientRect();
      return [-(y - (boundingRect.y + (boundingRect.height / 2))) / getIntensityY(mode), (x - (boundingRect.x + (boundingRect.width / 2))) / getIntensityX(mode), 1.02]
    }
  }
  const trans = (x: number, y: number, s: number) => `perspective(2000px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
  useEffect(() => {
    console.log({'dimensionalCardRef.current': dimensionalCardRef.current})
  }, [])
  return (
    <animated.div
      className={classes.card}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      ref={dimensionalCardRef}
      // @ts-ignore
      style={{ transform: properties.xys.interpolate(trans), height: '100%' }}
    >
      {children}
    </animated.div>
  )
}

export default DimensionalCard;
